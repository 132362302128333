<template>
    <div class="interview">
        <headLine msg='面试'></headLine>   
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left"  class="demo-table-expand">
                    <el-form-item label="姓名：">
                        <span>{{ props.row.zpResume.name }}</span>
                    </el-form-item>
                    <el-form-item label="应聘岗位：">
                        <span>{{ props.row.zpJob.jobName }}</span>
                    </el-form-item>
                     <el-form-item label="面试方式：">
                        <span class="red">{{ props.row.interviewType==1?'线上面试':props.row.interviewType==2?'现场面试':'不合适' }}</span>
                    </el-form-item>
                    <el-form-item label="面试时间：">
                        <span class="red">{{ props.row.interviewTime }}</span>
                    </el-form-item>
                    <el-form-item label="面试须知：">
                        <span>{{ props.row.interviewTypeDetail }}</span>
                    </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
            <el-table-column
            width="180"
            :show-overflow-tooltip="true"
            prop="dbName"
            label="双选会名称">     
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"  
            width="180"    
            label="应聘岗位">
                <template slot-scope="scope">
                    <span>{{scope.row.zpJob.jobName}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="姓名">
                <template slot-scope="scope">
                    <span>{{scope.row.zpResume.name}}</span>
                </template>
            </el-table-column>
             <el-table-column
            :show-overflow-tooltip="true"
            label="性别">
                 <template slot-scope="scope">
                    <span>{{scope.row.zpResume.sex==0?'女':'男'}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="年龄(岁)">
                 <template slot-scope="scope">
                    <span>{{scope.row.zpResume.birthday | getAge()}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="学历">
                 <template slot-scope="scope">
                    <span>{{scope.row.zpResume.education}}</span>
                </template>
            </el-table-column>
            <el-table-column
            :show-overflow-tooltip="true"
            label="手机号码">
                 <template slot-scope="scope">
                    <span>{{scope.row.zpResume.phone}}</span>
                </template>
            </el-table-column>
           <el-table-column
           width="90"
            :show-overflow-tooltip="true"
            label="面试方式">
                 <template slot-scope="scope">
                    <span class="interviewType" :class="scope.row.interviewType==1?'type2':scope.row.interviewType==2?'type1':'type3'">
                        {{ scope.row.interviewType==1?'线上面试':scope.row.interviewType==2?'现场面试':'不合适' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.state?scope.row.state==3?'warning':
                    scope.row.state==4?'success':'info'
                    :''"
                    disable-transitions>
                    {{scope.row.state?scope.row.state==3?'待面试':
                    scope.row.state==4?'已通过':scope.row.state==6?'未赴约':"未通过"
                    :''}}
                    </el-tag>       
                </template>      
            </el-table-column>
            <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                    <el-button
                    size="mini" 
                    type="danger"
                    @click="goMeetResult(scope.row.id)">
                    面试反馈
                    </el-button>  
                     <el-button
                    size="mini" 
                    type="primary"
                    @click="lookResumeDetail(scope.row.zpResume)">
                    查看简历
                    </el-button>
                    
                    <el-button
                    :disabled="!scope.row.showRoom" 
                    size="mini" 
                    type="success"
                    v-show="scope.row.interviewType==1"
                    @click="goVideoTalk(scope.row)">
                    在线面试
                    </el-button>  
                        
                </template>      
            </el-table-column>           
        </el-table>
        <paging v-show="tableData.length>0" :totalpage="totalpage" @getInitList="getInitList" ref="pages" v-on:handle="handle"></paging>
        <el-dialog title="面试反馈" :visible.sync="meetModel" width="40%"
        :closeOnClickModal="false">
            <el-form :model="form" >
                <el-form-item label="面试结果：" :label-width="formLabelWidth">
                    <el-radio-group v-model="form.state" >
                        <el-radio :label="4">通过</el-radio>
                        <el-radio :label="5">未通过</el-radio>
                        <el-radio :label="6">未赴约</el-radio>
                    </el-radio-group>
                </el-form-item>  
                 <el-form-item label="面试反馈：" :label-width="formLabelWidth" 
                 v-show="form.state!=6">
                    <el-input  
                    type="textarea"
                    rows="3"
                    v-model="form.describe"  
                    :placeholder="form.state==4?tip[0]:tip[1]"></el-input>
                </el-form-item> 
                   
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="meetModel = false" size="small">取 消</el-button>
                <el-button type="primary" @click="resultMeet" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {   
    data() {
        return {
            formLabelWidth:'100px',
            tip:[
                "请输入面试反馈信息，如后续报道流程及对接人",
                "请输入不通过原因"
            ],
            form:{
                state:4
            },
            tableData:[],
            companyId:'', 
            totalpage:0 ,
            current:0,
            meetModel:false,
            choseId:null     
        }
    },
    methods: {
        resultMeet(){
            let obj={...this.form}
            obj.id=this.choseId
            let url=`/hr/resume/job/result`;      
            this.$htp.post_(url,obj,true).then(()=>{
                this.meetModel=!this.meetModel
                this.getInitList(this.current)       
            }) 
        },
        handle(val){
            this.current=val  
        }, 
        goMeetResult(id){
            this.form={state:4}
            this.choseId=id
            this.meetModel=!this.meetModel
        },
        //进行在线视频面试
        goVideoTalk(rows){
            let {roomId,dbName,interviewTime,zpJob,zpResume,zpCompany,orgName}=rows
            let {jobName}=zpJob
            let {name}=zpResume

            let interviewInfo={
                roomId,dbName,interviewTime,jobName,name,orgName,
                companyName:zpCompany.companyName,
                userName:sessionStorage.getItem('nickName')
            }
            sessionStorage.setItem('interviewInfo',JSON.stringify(interviewInfo))
           
            if(!roomId){
                this.$allFun.getPromptBox('没有创建房间号，请联系对接人')
                return
            }
            this.$allFun.openNewWindow('videoTalk',this,roomId)
        },
        //查看简历
        lookResumeDetail(zpResume){
           sessionStorage.setItem('zpResume',JSON.stringify(zpResume))
           this.$allFun.openNewWindow('resumePerson',this)
        } ,
        getInitList(current=0){
            let url=`/hr/resume/job/meet/list?page=${current}&companyId=${this.companyId}`;      
            this.$htp.get_(url).then((res)=>{
                const {rows,total}=res.data
                this.tableData=rows 
                this.totalpage=total             
            }) 
        },
        
    },
    mounted(){
        this.companyId=sessionStorage.getItem('id')
        this.getInitList()    
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/common.scss';
.el-form-item {
    margin-bottom: 0px;
}
.interviewType{
    background-color: #909399;
    color: #fff;
    padding: 2px 6px;
    border-radius: 20px;
    font-size: 12px;
    display: inline-block;
}
.type1{
   background: #409EFF; 
}
.type2{
    background: #67C23A;
}
.type3{
    background: #909399;
}
</style>
